import React from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from './navigation';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import './assets/sass/global.scss';
import 'aos/dist/aos.css';
AOS.init({
	once: true,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Navigation />
	</React.StrictMode>
);
reportWebVitals();

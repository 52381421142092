// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDHRT_hlkO6qe0rqNfNtspp1EGTtevVtd4',
	authDomain: 'spira-tv-ia.firebaseapp.com',
	projectId: 'spira-tv-ia',
	storageBucket: 'spira-tv-ia.firebasestorage.app',
	messagingSenderId: '24929580906',
	appId: '1:24929580906:web:4280c7e0dedad0ee305713',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);

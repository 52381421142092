import { useState, useEffect } from 'react';
import { ref, onChildChanged } from 'firebase/database';
import { db } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Container, Grid } from '@mui/material';
import Chat from '../../components/Chat';
import ValidateSession from '../../components/ValidateSession';
import { download_file, downloadFile, getCollectionsComplete, getDate, updateData } from '../../assets/js/functions';
import logo from '../../assets/images/Spira-Color.png';
import bienvenidos from '../../assets/images/streaming/bienvenidos.svg';
import titulo from '../../assets/images/streaming/titulo.svg';
import dia1 from '../../assets/images/streaming/dia1.svg';
import dia2 from '../../assets/images/streaming/dia2.svg';
import camilo from '../../assets/images/streaming/camilo.svg';
import './styles.scss';

export default function Streaming() {
	const [streaming, setStreaming] = useState({});
	const [usuario, setUsuario] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		getInfo();
		// reporteChats();
		// reporteUsuarios();
		const dbRef = ref(db, `${process.env.REACT_APP_BD_CONFIG}/`);
		onChildChanged(dbRef, (data) => {
			getInfo();
		});
	}, []);

	const getInfo = async () => {
		let json = {};
		const dataStreaming = await getCollectionsComplete(`${process.env.REACT_APP_BD_CONFIG}`);
		const dataUsuario = await getCollectionsComplete(`${process.env.REACT_APP_BD_USUARIOS}/${sessionStorage.getItem('id')}`);

		if (dataStreaming.cambiar === false) {
			json = {
				...dataStreaming,
				url: dataStreaming.vimeo,
			};
		} else {
			json = {
				...dataStreaming,
				url: dataStreaming.respaldo,
			};
		}

		setStreaming(json);
		setUsuario(dataUsuario);
	};

	// const descarga = () => {
	// 	updateData(`${process.env.REACT_APP_BD_USUARIOS}`, `${sessionStorage.getItem('mx')}`, { descarga2: getDate() }).then(() => {
	// 		download_file('https://lideresengestionremotasura.spira.com.mx/DG_INFO_GESTION_REMOTA_sesion%202.pdf', 'Infografía Gestión Remotaa');
	// 	});
	// };

	return (
		<>
			<ValidateSession>
				<header>
					<div className='logo'>
						<img src={logo} alt='logo' />
					</div>
				</header>
				<div id='streaming'>
					{/* 
					<img src={logo} alt='logo' className='header' />
					<Grid container justifyContent='center' alignItems='center' spacing={2}>
						<Grid item xs={12} sm={9} md={9} lg={9} data-aos='fade-up' data-aos-delay='0' data-aos-duration='1000'>
							<img src={banner} alt='banner' className='full' />
						</Grid>
					</Grid>
							*/}
					<Container maxWidth='lg'>
						<Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
							<Grid item xs={12} sm={6} md={6} lg={5} data-aos='fade-up' data-aos-delay='0' data-aos-duration='1000'>
								<div className='center'>
									<img src={bienvenidos} alt='img' className='full logos' />
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={5} data-aos='fade-up' data-aos-delay='0' data-aos-duration='1000'>
								<div className='center'>
									<img src={titulo} alt='img' className='full logos' />
								</div>
							</Grid>
						</Grid>
					</Container>
					<Container maxWidth='lg' className='mt60'>
						<Grid container direction='row' justifyContent='stretch' spacing={2}>
							<Grid item lg={8} md={8} sm={12} xs={12} data-aos='zoom-in' data-aos-delay='0' data-aos-duration='1000'>
								<div className='abner'>
									<iframe src='https://vimeo.com/event/4740093/embed/interaction' frameBorder='0' allow='autoplay; fullscreen; picture-in-picture' allowFullScreen height={420} width='100%' style={{ borderRadius: '15px' }}></iframe>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12} data-aos='zoom-in' data-aos-delay='0' data-aos-duration='1000'>
								<p>Participa en el chat y comparte tus preguntas u opiniones</p>
								<Chat usuario={usuario} idConferencia={`sdfdsf800fds8`} />
							</Grid>
						</Grid>
					</Container>
					<Container maxWidth='lg' className=''>
						<Grid container direction='row' justifyContent='stretch' spacing={2}>
							<Grid item lg={12} md={12} sm={12} xs={12} data-aos='zoom-in' data-aos-delay='0' data-aos-duration='1000'>
								<h1>
									Dos días para <span>transformar</span>
									<br /> la formación organizacional con IA
								</h1>
							</Grid>
						</Grid>
					</Container>
					<Container maxWidth='lg' className=''>
						<Grid container direction='row' justifyContent='stretch' spacing={2}>
							<Grid item lg={6} md={12} sm={12} xs={12} data-aos='zoom-in' data-aos-delay='0' data-aos-duration='1000'>
								<Grid container direction='row' justifyContent='center' spacing={2}>
									<Grid item lg={3} md={3} sm={3} xs={5}>
										<div className='center'>
											<img src={dia1} alt='img' className='full' />
										</div>
									</Grid>
									<Grid item lg={9} md={9} sm={9} xs={12}>
										<h2>IA en la formación organizacional</h2>
										<p className='texto-evento'>
											En estos primeros 45 minutos te contaremos algunos retos y desafíos que enfrentan las organizaciones en sus procesos de formación cuando dejan de lado el desarrollo tecnológico. Conocerás cómo en Spira superamos estos límites tecnológicos.
										</p>
									</Grid>
								</Grid>
							</Grid>
							<Grid item lg={6} md={12} sm={12} xs={12} data-aos='zoom-in' data-aos-delay='0' data-aos-duration='1000'>
								<Grid container direction='row' justifyContent='center' spacing={2}>
									<Grid item lg={3} md={3} sm={3} xs={5}>
										<div className='center'>
											<img src={dia2} alt='img' className='full' />
										</div>
									</Grid>
									<Grid item lg={9} md={9} sm={9} xs={12}>
										<h2>Revolucionando la formación con IA</h2>
										<p className='texto-evento'>
											Serán 45 minutos enriquecedores en los que verás cómo la tecnología puede llevar el talento a otro nivel! Además…resolveremos tus dudas y podrás recibir un regalo que te acercará a esta revolución tecnológica e implementarlo en tu organización.
										</p>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Container>
					<Container maxWidth='lg' className='mt60'>
						<div className='contenedor' data-aos='zoom-in' data-aos-delay='0' data-aos-duration='1000'>
							<Grid container direction='row' justifyContent='center' spacing={2}>
								<Grid item lg={4} md={4} sm={4} xs={12} className='border'>
									<div className='center'>
										<img src={camilo} alt='img' className='full' />
									</div>
								</Grid>
								<Grid item lg={8} md={8} sm={8} xs={12}>
									<div className='flex'>
										<div className='izq'>
											<div className='linea' />
										</div>
										<div className='der'>
											<h2>Camilo vergara</h2>
											<small>CEO Spira</small>
											<p>Es un líder en formación con IA, desarrollo organizacional y Universidades Corporativas. Con 15 años de experiencia, ha transformado a más de 25,000 personas y 400 organizaciones, impulsando resultados empresariales a través de la innovación.</p>
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</Container>
				</div>
			</ValidateSession>
		</>
	);
}

import { getCollections, getDate, cambiarTextos } from './functions';
let xlsx = require('json-as-xlsx');

export async function reporteUsuarios() {
	let array = [];
	const results = await getCollections('usuarios');
	Object.values(results).map((val) => {
		array.push({
			...val,
			asistencia1: val.asistencia1 ? val.asistencia1 : 'No',
			asistencia2: val.asistencia2 ? val.asistencia2 : 'No',
		});
	});
	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'Usuarios',
			columns: [
				{ label: 'ID', value: 'id' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Empresa', value: 'empresa' },
				{ label: 'Correo', value: 'email' },
				{ label: 'Asistencia 1', value: 'asistencia1' },
				{ label: 'Asistencia 2', value: 'asistencia2' },
			],
			content: array,
		},
	];
	// console.log(array);

	let settings = {
		fileName: `Asistencias-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function reporteChats() {
	let array = [];
	const results = await getCollections('chat/sdfdsf800fds8');
	Object.values(results).map((val) => {
		array.push({
			id: val.id,
			nombre: val.nombre,
			texto: val.text,
		});
	});
	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'USuarios',
			columns: [
				{ label: 'ID', value: 'id' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Mensaje', value: 'texto' },
			],
			content: array,
		},
	];
	// console.log(array);

	let settings = {
		fileName: `SPIRA-TV-IA-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from '../views/Login';
import Streaming from '../views/Streaming';
// import Encuesta from '../views/Encuesta';
import ErrorPublica from '../views/ErrorPublica';

export default function Navigation() {
	return (
		<>
			<Router>
				<Routes>
					<Route path='/' element={<Login />} />
					<Route path='/streaming' element={<Streaming />} />
					{/* <Route path='/encuesta' element={<Encuesta />} /> */}
					<Route path='*' element={<ErrorPublica />} />
				</Routes>
			</Router>
		</>
	);
}

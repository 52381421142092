import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField } from '@mui/material';
import uniqid from 'uniqid';
import { updateData, getDate, searchDataByValue } from '../../assets/js/functions';
import logo from '../../assets/images/Spira-Color.png';
import bienvenido from '../../assets/images/login/bienvenido.svg';
import titulo from '../../assets/images/login/titulo.svg';
import './styles.scss';
import { reporteUsuarios, reporteChats } from '../../assets/js/reportes';

export default function Login() {
	const [campos, setCampos] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		// 	validarRegistro()
		// reporteChats();
	}, []);

	// const validarRegistro = async ()=>{

	// }

	const validar = async (e) => {
		e.preventDefault();
		const id = uniqid();
		const data = await searchDataByValue(`${process.env.REACT_APP_BD_USUARIOS}`, 'email', campos.email);
		// console.log(data);

		if (Object.keys(data).length > 0) {
			sessionStorage.setItem('id', data.id);
			updateData(process.env.REACT_APP_BD_USUARIOS, data.id, { ingreso2: getDate(), asistencia2: 'Sí' })
				.then(() => {
					navigate('/streaming');
				})
				.catch((e) => {
					console.log('Error: ' + e);
				});
		} else {
			sessionStorage.setItem('id', id);
			updateData(process.env.REACT_APP_BD_USUARIOS, id, { id, ...campos, ingreso2: getDate(), asistencia2: 'Sí' })
				.then(() => {
					navigate('/streaming');
				})
				.catch((e) => {
					console.log('Error: ' + e);
				});
		}
	};

	return (
		<>
			<header>
				<div className='logo'>
					<img src={logo} alt='logo' />
				</div>
			</header>
			<div id='login'>
				<Grid container justifyContent='center' alignItems='center' spacing={4}>
					<Grid item xs={12} sm={7} md={7} lg={5} data-aos='zoom-in' data-aos-delay='0' data-aos-duration='1000'>
						<div className='center'>
							<img src={titulo} alt='img' className='img-izq' />
						</div>
					</Grid>
					<Grid item xs={12} sm={4} md={4} lg={5} data-aos='zoom-in' data-aos-delay='500' data-aos-duration='1000'>
						<form onSubmit={validar}>
							<Grid container justifyContent='center' alignItems='center' spacing={4}>
								<Grid item xs={12} sm={12} md={8} lg={8}>
									<div className='center'></div>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={8}>
									<img src={bienvenido} alt='img' className='full' />
									<div className='texto'>Completa los siguientes datos y haz parte del evento:</div>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={8}>
									<div className='abner'>
										<TextField required label='Nombre' variant='filled' fullWidth value={campos.nombre} onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} />
									</div>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={8}>
									<div className='abner'>
										<TextField required type='email' label='Email corporativo' variant='filled' fullWidth value={campos.email} onChange={(e) => setCampos({ ...campos, email: e.target.value })} />
									</div>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={8}>
									<div className='abner'>
										<TextField required label='Empresa' variant='filled' fullWidth value={campos.empresa} onChange={(e) => setCampos({ ...campos, empresa: e.target.value })} />
									</div>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={8} className='center'>
									<button type='submit'>Ingresar</button>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</div>
		</>
	);
}
